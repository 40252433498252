import React from 'react';
import { graphql } from 'gatsby';
import Slider from "react-slick";

import SEO from '../../components/Seo';
import './Work.scss'
import Layout from '../../layout/Layout';
import Nav from '../../components/Nav/Nav';
import VideoPlayer from '../../components/Video/VideoPlayer/VideoPlayer'
import ImageCreation from '../../components/Image/ImageCreation/ImageCreation'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NotFoundPage from '../404';


const CreationItem = ({ data }) => {
  const creationItemData = data?.allContentfulCreationItem?.nodes[0]
  const creationPageList = data?.creationPageList?.nodes

  const currentPagePos  = creationPageList.findIndex(x => x.slug ===creationItemData.slug);

  const SliderSettings = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
    swipeToSlide: true,
    responsive: [{
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  };
  if(!creationItemData){
    <NotFoundPage />
  }

  return (
    <Layout>
      <SEO
        lang="fr"
        title={creationItemData.creationTitle}
        description='Publicis et Nous'
      />
      <div id="main-wrapper" className="row">
        <Nav />
        <section id="container" className="landing col-lg-9 col-md-12 mt-2">
          <div className="landing-page">
            {
              creationItemData.creationContentRef?.map((creationItem, index1) =>(
                <div key={index1}>
                  {
                    creationItem.hasOwnProperty('creationItemInsideTwoColRef') && 
                    
                      <div className='home-demo mt-6'>
                        <div className="home-slider">
                          <Slider {...SliderSettings} className={`${creationItem.creationItemInsideTwoColRef.length == 1 ? 'solo_photo_left' : ''}`}>
                            {
                              creationItem.creationItemInsideTwoColRef?.map((creationItemTwoCol, index2) =>(
                                <div className={'item slide-'+(index2+1) } key={index2}>
                                  {
                                    creationItemTwoCol?.hasOwnProperty('photo') &&
                                      <ImageCreation img={creationItemTwoCol?.photo?.gatsbyImageData} alt={creationItemTwoCol?.creationContentName || ''}/>
                                  }
                                  {
                                    creationItemTwoCol?.hasOwnProperty('videoFile') &&
                                      <VideoPlayer videoFileUrl={creationItemTwoCol.videoFile.file.url} dataFullScreen={false}/>
                                  }
                                </div>
                              ))
                            }
                          </Slider>
                        </div>
                      </div>
                  }
                  {
                    creationItem.hasOwnProperty('photo') && 
                      <div className={`col-12 col-sm-12 text-center landing-full mt-6`}>
                        <ImageCreation img={creationItem.photo?.gatsbyImageData} alt={creationItem?.creationContentName || ''}/>
                      </div>
                  }
                  {
                    creationItem.hasOwnProperty('videoFile') && 
                      <div className={`video-container mt-6`}>
                        <VideoPlayer videoFileUrl={creationItem.videoFile.file.url} dataFullScreen={true} />
                      </div>
                      
                  }
                  {
                    creationItem.hasOwnProperty('textDescription') && 
                      <div className={`content-hp-middle col-lg-12 mt-6`}>
                        <div className="title" dangerouslySetInnerHTML={ {  __html: creationItem.textDescription.childMarkdownRemark.html}} />
                      </div>
                  }
                </div>
              ))
            }
            <div className="col-12 col-sm-12 text-center btnProject">
              {
                currentPagePos > 0 &&
                <a href={`/creations/${creationPageList[currentPagePos-1].slug}`} className="btnPrevious">Back</a>
              }
              {
                currentPagePos < creationPageList.length-1  &&
                <a href={`/creations/${creationPageList[currentPagePos+1].slug}`} className="btnNext">Next project</a>
              }
            </div>

          </div>
        </section>
      </div>
    </Layout>
  )
};


export const query = graphql`
  query($slug : String!) {
    creationPageList : allContentfulCreationItem(
      sort: {fields: order}
      filter: {showCreation: {ne: false}}
    ) {
      nodes {
        slug
      }
    }
    allContentfulCreationItem(filter: {slug: {eq: $slug}}) {
      nodes {
        slug
        creationName
        creationTitle
        order
        creationContentRef {
          ... on ContentfulCreationItemTwoColumn {
            creationItemInsideTwoColRef {
              ... on ContentfulCreationContentPhoto {
                creationContentName
                photo {
                  gatsbyImageData
                }
              }
              ... on ContentfulCreationContentVideo {
                videoFile {
                  file {
                    url
                  }
                }
              }
            }
          }
          ... on ContentfulCreationContentPhoto {
            creationContentName
            photo {
              gatsbyImageData
            }
          }
          ... on ContentfulCreationContentText {
            textDescription {
              childMarkdownRemark {
                html
              }
            }
          }
          ... on ContentfulCreationContentVideo {
            videoFile {
              file {
                url
              }
            }
          }
        }
      }
    }

  }
  `

export default CreationItem;